import React, { createContext, useContext, useEffect, useState } from 'react';
import { 
  User,
  signInWithPopup,
  signOut as firebaseSignOut,
  browserLocalPersistence,
  setPersistence,
  onAuthStateChanged
} from 'firebase/auth';
import { auth, db, googleProvider } from '../lib/firebase';
import { doc, setDoc, getDoc } from 'firebase/firestore';
import toast from 'react-hot-toast';

interface AuthContextType {
  user: User | null;
  loading: boolean;
  signInWithGoogle: () => Promise<void>;
  signInWithTest: () => Promise<void>;
  signOut: () => Promise<void>;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

// Test user data
const TEST_USER_ID = 'T51xCQ6JhvcQBYDwuoYYjrVeWqS2';
const TEST_USER_DATA = {
  email: "me@simonberg.ai",
  name: "Simon Berg",
  photoURL: "https://lh3.googleusercontent.com/a/ACg8ocIBNCrV18JhuI_h_XdjgyZx11A=s96-c",
  linkedinProfile: "https://www.linkedin.com/in/simonbergsays/",
};

export function AuthProvider({ children }: { children: React.ReactNode }) {
  const [user, setUser] = useState<User | null>(null);
  const [loading, setLoading] = useState(true);
  const [isTestUser, setIsTestUser] = useState(false);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user && !isTestUser) {
        setUser(user);
        await createUserDocument(user);
      } else if (!isTestUser) {
        setUser(null);
      }
      setLoading(false);
    });

    return unsubscribe;
  }, [isTestUser]);

  const handleAuthError = (error: any) => {
    console.error('Auth error:', error);
    let message = 'An error occurred during authentication';

    switch (error.code) {
      case 'auth/unauthorized-domain':
        message = 'This domain is not authorized for authentication. Please try again later.';
        break;
      case 'auth/popup-blocked':
        message = 'Popup was blocked. Please allow popups and try again.';
        break;
      case 'auth/cancelled-popup-request':
      case 'auth/popup-closed-by-user':
        return; // Don't show error for user-cancelled actions
      default:
        message = error.message || message;
    }

    toast.error(message);
  };

  const createUserDocument = async (user: User) => {
    if (!user.uid) return;
    
    try {
      const userRef = doc(db, 'users', user.uid);
      const userSnap = await getDoc(userRef);
      
      if (!userSnap.exists()) {
        await setDoc(userRef, {
          ...TEST_USER_DATA,
          createdAt: new Date().toISOString()
        });
      }
    } catch (error) {
      console.error('Error creating user document:', error);
      toast.error('Failed to create user profile');
    }
  };

  const signInWithGoogle = async () => {
    try {
      await setPersistence(auth, browserLocalPersistence);
      const result = await signInWithPopup(auth, googleProvider);
      if (result.user) {
        await createUserDocument(result.user);
        toast.success('Successfully signed in!');
      }
    } catch (error: any) {
      handleAuthError(error);
      throw error;
    }
  };

  const signInWithTest = async () => {
    try {
      // Get the test user document
      const userRef = doc(db, 'users', TEST_USER_ID);
      const userSnap = await getDoc(userRef);
      
      if (!userSnap.exists()) {
        // Create test user if it doesn't exist
        await setDoc(userRef, {
          ...TEST_USER_DATA,
          createdAt: new Date().toISOString()
        });
      }

      // Create a custom user object that matches Firebase User interface
      const testUser = {
        uid: TEST_USER_ID,
        email: TEST_USER_DATA.email,
        displayName: TEST_USER_DATA.name,
        photoURL: TEST_USER_DATA.photoURL,
        emailVerified: true,
        isAnonymous: false,
        metadata: {
          creationTime: Date.now().toString(),
          lastSignInTime: Date.now().toString()
        },
        providerData: [{
          providerId: 'test',
          uid: TEST_USER_ID,
          displayName: TEST_USER_DATA.name,
          email: TEST_USER_DATA.email,
          phoneNumber: null,
          photoURL: TEST_USER_DATA.photoURL
        }],
        delete: async () => {},
        getIdToken: async () => 'test-token',
        getIdTokenResult: async () => ({
          token: 'test-token',
          signInProvider: 'test',
          claims: {},
          authTime: new Date().toISOString(),
          issuedAtTime: new Date().toISOString(),
          expirationTime: new Date(Date.now() + 3600000).toISOString(),
        }),
        reload: async () => {},
        toJSON: () => ({}),
        _stopProactiveRefresh: () => {},
        _startProactiveRefresh: () => {}
      } as unknown as User;

      setIsTestUser(true);
      setUser(testUser);
      toast.success('Successfully signed in with test account!');
    } catch (error: any) {
      console.error('Test login error:', error);
      toast.error('Failed to sign in with test account');
      throw error;
    }
  };

  const signOut = async () => {
    try {
      if (isTestUser) {
        setUser(null);
        setIsTestUser(false);
      } else {
        await firebaseSignOut(auth);
      }
      toast.success('Successfully signed out!');
    } catch (error) {
      console.error('Error signing out:', error);
      toast.error('Failed to sign out');
      throw error;
    }
  };

  const value = {
    user,
    loading,
    signInWithGoogle,
    signInWithTest,
    signOut
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
}

export function useAuth() {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
}