import { initializeApp } from 'firebase/app';
import { getAuth, GoogleAuthProvider } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
  apiKey: "AIzaSyBZ5Z6o-DdSmuHlNw8lLS_i37SoGMGC0vg",
  authDomain: "prep-me-19321.firebaseapp.com",
  projectId: "prep-me-19321",
  storageBucket: "prep-me-19321.firebasestorage.app",
  messagingSenderId: "731878634918",
  appId: "1:731878634918:web:95886a50ff0bae29accb67",
  measurementId: "G-ZBD1TDBHLD"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);
export const googleProvider = new GoogleAuthProvider();

// Configure Google provider
googleProvider.addScope('profile');
googleProvider.addScope('email');

// Set custom parameters
googleProvider.setCustomParameters({
  prompt: 'select_account'
});

// Enable test mode for development
if (import.meta.env.DEV) {
  auth.useDeviceLanguage();
  // @ts-ignore
  window.firebase = { auth, db }; // For debugging
}