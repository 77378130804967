import React, { useState } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';

export default function LoginPage() {
  const { signInWithGoogle, signInWithTest } = useAuth();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [isTestLoading, setIsTestLoading] = useState(false);

  const handleGoogleLogin = async () => {
    setIsLoading(true);
    try {
      await signInWithGoogle();
      navigate('/');
    } finally {
      setIsLoading(false);
    }
  };

  const handleTestLogin = async () => {
    setIsTestLoading(true);
    try {
      await signInWithTest();
      navigate('/');
    } finally {
      setIsTestLoading(false);
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-blue-50 to-indigo-50 flex flex-col items-center justify-center p-6">
      <div className="max-w-md w-full bg-white rounded-xl shadow-lg p-8">
        <h1 className="text-3xl font-bold text-center text-gray-900 mb-8">
          Welcome to Prep Me
        </h1>
        
        <div className="space-y-4">
          <p className="text-center text-gray-600 mb-8">
            Sign in to analyze LinkedIn profiles and get personalized insights
          </p>
          
          <button
            onClick={handleGoogleLogin}
            disabled={isLoading}
            className={`w-full flex items-center justify-center gap-3 bg-white border-2 border-gray-200 text-gray-800 px-6 py-3 rounded-lg transition-colors ${
              isLoading ? 'opacity-50 cursor-not-allowed' : 'hover:bg-gray-50'
            }`}
          >
            <img 
              src="https://www.google.com/favicon.ico" 
              alt="Google" 
              className="w-5 h-5"
            />
            {isLoading ? 'Signing in...' : 'Sign in with Google'}
          </button>

          {/* Test Login Button - Only shown in development */}
          {import.meta.env.DEV && (
            <button
              onClick={handleTestLogin}
              disabled={isTestLoading}
              className={`w-full flex items-center justify-center gap-3 bg-green-600 text-white px-6 py-3 rounded-lg transition-colors ${
                isTestLoading ? 'opacity-50 cursor-not-allowed' : 'hover:bg-green-700'
              }`}
            >
              {isTestLoading ? 'Signing in...' : 'Use Test Account'}
            </button>
          )}
        </div>

        <div className="mt-8 text-center text-sm text-gray-500">
          <p>By signing in, you agree to our Terms of Service and Privacy Policy</p>
        </div>
      </div>
    </div>
  );
}