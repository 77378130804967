import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import './index.css';
import { app } from './lib/firebase';

// Ensure Firebase is initialized
if (app) {
  const root = createRoot(document.getElementById('root')!);
  root.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>
  );
}