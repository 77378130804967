import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { ArrowLeft, Briefcase, MapPin, Building, MessageCircle } from 'lucide-react';
import { useAuth } from '../contexts/AuthContext';
import { collection, addDoc, getDoc, doc, getDocs, query, where, limit } from 'firebase/firestore';
import { db } from '../lib/firebase';
import toast from 'react-hot-toast';
import NotesEditor from './NotesEditor';

export default function ProfileAnalysis() {
  const navigate = useNavigate();
  const location = useLocation();
  const { user } = useAuth();
  const { profileData, from } = location.state || {};
  const [prepId, setPrepId] = useState<string | null>(null);
  const [notes, setNotes] = useState('');

  useEffect(() => {
    if (!profileData || !user) {
      navigate('/login');
      return;
    }

    const initializePrep = async () => {
      try {
        // Check if a prep already exists for this profile
        const prepsRef = collection(db, 'preps');
        const q = query(
          prepsRef, 
          where('userId', '==', user.uid),
          where('profileData.personalInfo.name', '==', profileData.personalInfo.name),
          limit(1)
        );
        
        const querySnapshot = await getDocs(q);
        let docRef;

        if (!querySnapshot.empty) {
          // Use existing prep
          docRef = querySnapshot.docs[0].ref;
          const existingPrep = querySnapshot.docs[0].data();
          setPrepId(querySnapshot.docs[0].id);
          setNotes(existingPrep.notes || '');
        } else {
          // Create new prep
          docRef = await addDoc(prepsRef, {
            userId: user.uid,
            profileData,
            createdAt: new Date().toISOString(),
            notes: '',
            lastUpdated: new Date().toISOString()
          });
          setPrepId(docRef.id);
        }

      } catch (error) {
        console.error('Error initializing prep:', error);
        toast.error('Failed to initialize prep. Please try again.');
      }
    };

    initializePrep();
  }, [profileData, user, navigate]);

  if (!user || !profileData) {
    return null;
  }

  return (
    <div className="max-w-4xl mx-auto p-6">
      <div className="mb-6">
        <button 
          onClick={() => navigate(from === 'myPreps' ? '/my-preps' : '/search')}
          className="flex items-center gap-2 text-[#E86C1F] hover:text-[#D65A0D]"
        >
          <ArrowLeft size={20} />
          {from === 'myPreps' ? 'Back to My Preps' : 'Back to Search'}
        </button>
      </div>

      <div className="bg-white rounded-xl shadow-lg overflow-hidden">
        {/* Profile Header */}
        <div className="p-8 border-b">
          <div className="flex items-center gap-6">
            {profileData?.personalInfo?.avatar && (
              <img 
                src={profileData.personalInfo.avatar}
                alt={profileData.personalInfo.name}
                className="w-24 h-24 rounded-full object-cover"
              />
            )}
            <div>
              <h1 className="text-3xl font-varela text-gray-900">{profileData?.personalInfo.name}</h1>
              <p className="text-xl text-gray-600 mt-2">{profileData?.personalInfo.title}</p>
              <div className="flex items-center gap-2 mt-2 text-gray-600">
                <MapPin size={18} />
                <span>{profileData?.personalInfo.location}</span>
              </div>
            </div>
          </div>
        </div>

        {/* Experience Section */}
        <div className="p-8 border-b">
          <div className="flex items-center mb-4">
            <h2 className="text-2xl font-varela flex items-center gap-2">
              <Briefcase className="text-[#E86C1F]" />
              Experience
            </h2>
          </div>
          <div className="space-y-6">
            {profileData?.sections.careerHistory.map((experience: any, index: number) => (
              <div key={index} className="border-l-2 border-[#E86C1F] pl-4">
                <h3 className="font-semibold text-lg">{experience.title}</h3>
                <p className="text-gray-600">{experience.period}</p>
                <p className="text-gray-700 mt-2">{experience.description}</p>
              </div>
            ))}
          </div>
        </div>

        {/* Conversation Starters */}
        <div className="p-8 border-b">
          <div className="flex items-center mb-4">
            <h2 className="text-2xl font-varela flex items-center gap-2">
              <Building className="text-[#E86C1F]" />
              Conversation Starters
            </h2>
          </div>
          <div className="space-y-4">
            {profileData?.sections.conversationStarters.map((starter: any, index: number) => (
              <div key={index} className="bg-gray-50 p-4 rounded-lg">
                <h3 className="font-semibold">{starter.title}</h3>
                <p className="text-gray-700 mt-1">{starter.description}</p>
              </div>
            ))}
          </div>
        </div>

        {/* Notes Section */}
        <div className="p-8">
          <div className="flex items-center mb-4">
            <h2 className="text-2xl font-varela flex items-center gap-2">
              <MessageCircle className="text-[#E86C1F]" />
              Meeting Notes
            </h2>
          </div>
          <div className="relative z-10">
            {prepId && (
              <NotesEditor 
                prepId={prepId} 
                initialContent={notes}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}