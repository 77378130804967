import { loadStripe } from '@stripe/stripe-js';

// Get this from Stripe Dashboard -> Developers -> API keys -> Publishable key
const STRIPE_PUBLIC_KEY = import.meta.env.VITE_STRIPE_PUBLIC_KEY || '';

// Get these from Stripe Dashboard -> Products -> Select Product -> Pricing
export const STRIPE_PRICES = {
  // Price ID for Individual plan
  INDIVIDUAL: 'price_1QL53zLfhwjKEs9cPxTZA1mX',
  // Price ID for Pro plan
  PRO: 'price_1QL55ALfhwjKEs9c67RfUgLm'
};

let stripePromise: Promise<any>;
export const getStripe = () => {
  if (!stripePromise) {
    stripePromise = loadStripe(STRIPE_PUBLIC_KEY);
  }
  return stripePromise;
};

export const createCheckoutSession = async (priceId: string, userId: string) => {
  try {
    const response = await fetch('/.netlify/functions/create-checkout', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        priceId,
        userId,
        successUrl: `${window.location.origin}/profile?session_id={CHECKOUT_SESSION_ID}`,
        cancelUrl: `${window.location.origin}/pricing`,
      }),
    });

    const data = await response.json();
    
    if (data.error) {
      throw new Error(data.message || 'Failed to create checkout session');
    }

    const stripe = await getStripe();
    if (!stripe) throw new Error('Stripe failed to load');

    const { error } = await stripe.redirectToCheckout({ sessionId: data.id });
    if (error) throw error;

    return data;
  } catch (error: any) {
    console.error('Error creating checkout session:', error);
    throw new Error(error.message || 'Failed to create checkout session');
  }
};